<template>
  <div>
    <van-cell-group :title="t('UserInfo')">
      <van-cell :title="t('Username')" :value="userData.username" />
      <van-cell center :title="t('2fa')">
        <template #right-icon>
          <van-switch @change="change2fa" v-model="twoFAValue" size="24" />
        </template>
      </van-cell>
      <van-cell :title="t('ERC20')" :label="userData.walletAddress">
        <!-- <div slot="default">
        <van-button @click="$refs.EditERC20.open(userData.walletAddress)" type="info" plain size="mini" icon="edit"></van-button>
        </div> -->
      </van-cell>
      <van-cell :title="t('sn')" :label="userData.sn"></van-cell>
      <van-cell :title="t('DeeperChainWallet')">
        <div slot="label" style="word-break: break-all">
          <span v-if="!needCreateWallet">{{ deeperChain }}</span>
        </div>
      </van-cell>
      <van-cell
        v-if="stakingDeeperChain && stakingDeeperChain != deeperChain"
        :title="$t('user.haveStakedDeeperChain')"
      >
        <div slot="label" style="word-break: break-all; color: orangered">
          <span>{{ stakingDeeperChain }}</span>
          <div>{{ $t("user.deeperChainError") }}</div>
        </div>
      </van-cell>
      <template
        v-if="
          deviceSNType == 'IGG' || deviceSNType == 'HW' || deviceSNType == 'HWA'
        "
      >
        <van-cell
          :title="$t('user.openMining')"
          :label="$t('user.openMiningTips')"
        >
          <div slot="default">
            <van-icon size="30" color="#07c160" v-if="openNow" name="passed" />
            <van-icon size="30" color="#ff976a" v-else name="close" />
          </div>
        </van-cell>
      </template>

      <van-cell :title="$t('user.stakedDPR')" :value="stakingAmount"></van-cell>
      <van-cell :title="$t('user.credit')" :value="nowScore"></van-cell>

      <!-- <van-cell v-if="!validateEmail(userData.username)" :title="t('email')" @click="$refs.EditEmail.open(userData.email)" :value="userData.email" is-link></van-cell> -->
    </van-cell-group>
    <div style="padding: 30px">
      <!-- <div style="text-align: center">{{$t('user.waitingStart')}}</div> -->
      <van-button
        v-if="nowScore < 800"
        :disabled="getNextDisable()"
        block
        type="primary"
        @click="$emit('next')"
        >{{ $t("user.startStake") }}</van-button
      >
    </div>
    <div style="padding: 0 30px 10px">
      <a
        target="_blank"
        :href="
          'https://staking.deeper.network/stakingData?deeperChain=' +
          (stakingDeeperChain || '')
        "
        ><van-button block type="default">{{
          $t("user.searchDeeperChainStakingData")
        }}</van-button></a
      >
    </div>
    <div style="text-align: right; padding: 20px">
      <van-button @click="openChangeErc20" size="small">{{
        $t("user.changeErc20")
      }}</van-button>
    </div>
    <van-cell-group :title="$t('user.stakingRecord')">
      <table class="deeperTable">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("user.quantity") }}</th>
            <th>{{ $t("user.credit") }}</th>
            <th>{{ $t("user.status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in stakingRecord" :key="item._id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.dprAmountNum }}</td>
            <td>
              {{ item.fromCredit }}
              <van-icon style="transform: rotate(-90deg)" name="down" />
              {{ item.toCredit }}
            </td>
            <td>
              <span style="color: green" v-if="item.checkSet">{{
                $t("user.success")
              }}</span>
              <span style="color: orange" v-else>{{ $t("user.pending") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </van-cell-group>
    <!-- <div style="text-align: center" >
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBRBy7jyccJwdCJzWliDxWQ63deV0jSFebFxlEA4U8hHyWKg/viewform" target="_blank">Information modification application form</a>
    </div> -->
    <van-popup
      style="padding: 30px; width: 100%; max-width: 550px"
      v-model="editErc20Modal"
    >
      <div>
        <van-form style="width: " @submit="submitErc20">
          <van-field
            v-model="newErc20"
            name="BEP20"
            label="BEP20"
            :placeholder="$t('register.inputWalletAddress')"
            :rules="[{ required: true }]"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit">{{
              $t("user.Submit")
            }}</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
const { getScore } = require("./score");
const { getStakingData } = require("./service");
export default {
  components: {},
  props: {
    comps: null,
  },
  data() {
    return {
      editErc20Modal: false,
      newErc20: "",
      twoFAValue: false,
      deeperChain: "",
      deviceSNType: "",
      haveOpenMiningBefore: "",
      openNow: "",
      isInOpenList: "",
      stakingAmount: "",
      stakingDeeperChain: "",
      stakingObj: {},
      userData: {
        phone: "",
        walletAddress: "",
      },
      scoreMap: {},
      nowScore: 0,
      needCreateWallet: false,
      stakingCreditData: null,
      stakingRecord: [],
      havePendingRecord: false,
      checkOpenMiningBeforeLoading: true,
    };
  },
  async mounted() {
    this.$toast.loading({
      forbidClick: true,
      duration: 0,
      overlay: true,
    });
    await this.getData();

    this.getUser2fa();
    await this.checkOpenMiningBefore();
    await this.getDeeperChain();
    await this.getStakingData();
    await this.getStakingRecord();
    this.getScoreData();
    this.$toast.clear();
  },
  beforeDestroy() {
    this.$toast.clear();
  },
  methods: {
    getStakingRecord() {
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        url: "/user/stakingRecord",
        method: "GET",
      }).then((res) => {
        console.log(res);
        this.stakingRecord = res.data;
        this.havePendingRecord = !!res.data.filter(
          (it) => !it.checkSet || !it.haveSent
        ).length;
      });
    },
    openChangeErc20() {
      this.editErc20Modal = true;
    },
    submitErc20() {
      if (!/^(0x)?[0-9a-fA-F]{40}$/.test(this.newErc20)) {
        return this.$ctToast("请输入正确的BSC钱包地址");
      }
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        url: "/user/erc20",
        method: "PUT",
        data: {
          erc20: this.newErc20,
        },
      }).then((res) => {
        if (res.success) {
          this.$ctToast("修改成功，请重新登录");
          setTimeout(() => {
            window.localStorage.clear();
            window.location.href = "/login";
          }, 3000);
        }
      });
    },
    reload() {
      window.location.reload();
    },
    getNextDisable() {
      if (this.checkOpenMiningBeforeLoading) {
        return true;
      }
      if (this.havePendingRecord) {
        return true;
      }
      if (
        this.stakingDeeperChain &&
        this.stakingDeeperChain != this.deeperChain
      ) {
        return true;
      }
      if (!this.deeperChain) {
        return true;
      }
      if (this.nowScore >= 800) {
        return true;
      }
      return false;
    },
    getDeeperChain() {
      return this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        url: "/user/deeperChain",
      }).then((res) => {
        console.log("getDeeperChain");
        if (res.success) {
          if (res.data) {
            this.deeperChain = res.data;
          } else {
            this.$Dialog({
              message: this.$t("user.noWalletAndCreate"),
            });
          }
        }
        console.log(res);
      });
    },
    getScoreData() {
      let scoreSNType = this.deviceSNType;
      if (
        this.stakingObj.creditData &&
        (this.stakingObj.creditData.campaignId == 0 ||
          this.stakingObj.creditData.campaignId == 1)
      ) {
        scoreSNType = "DPRA" + this.stakingObj.creditData.campaignId;
      }
      this.scoreMap = getScore(scoreSNType, this.haveOpenMiningBefore);
      console.log("getScoreData");
      console.log(this.scoreMap);
    },
    async getStakingData() {
      const staking = await getStakingData();
      console.log(staking);
      if (!staking) {
        return;
      }
      this.stakingObj = staking;
      this.stakingAmount = (staking.dprAmount / 1e18).toFixed(4) / 1;
      this.nowScore = staking.credit;
      this.stakingDeeperChain = staking.deeperChain;
      this.stakingCreditData = staking.creditData;
    },
    getUser2fa() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/user/2fa",
      }).then((res) => {
        if (res.data) {
          this.twoFAValue = res.data.status == "OPEN" ? true : false;
        }
      });
    },
    change2fa(value) {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/user/2fa",
        method: "POST",
        data: {
          status: value ? "OPEN" : "CLOSE",
        },
      }).then((res) => {
        if (res.data) {
          this.twoFAValue = res.data.status == "OPEN" ? true : false;
        }
      });
    },
    t(word) {
      return this.$t(`user.${word}`);
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/user/getAccount",
      })
        .then((res) => {
          this.userData = res.data;
          if (this.userData.sn) {
            this.deviceSNType = this.userData.sn.split("-")[2];
          }
        })
        .catch((e) => {
          if (e === "notAuthenticated" || e === "notRegistered") {
            this.$router.replace("/login");
            window.localStorage.clear();
          }
        });
    },
    checkOpenMiningBefore() {
      this.checkOpenMiningBeforeLoading = true;
      return this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
        url: "/user/checkOpenMiningBefore",
      })
        .then((res) => {
          if (res.success) {
            this.haveOpenMiningBefore = !!res.data.openBefore;
            this.openNow = res.data.checkOpen;
          }
        })
        .finally(() => {
          this.checkOpenMiningBeforeLoading = false;
        });
    },
  },
};
</script>

<style></style>
