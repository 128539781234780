import Web3 from "web3";

const DPR = require("@/coins/DPR/DPR.js");
const DeviceStake = require("@/coins/DeviceStake/DeviceStake");

let dprContract;
let dprTestContract;

window.BN = Web3.utils.BN;
const BN = window.BN;

let web3;

async function init(config = {}) {
  if (window.ethereum) {
    await window.ethereum.send("eth_requestAccounts");
    window.ethereum.on("accountsChanged", function (_accounts) {
      if (config.onAccountsChanged) {
        return config.onAccountsChanged(_accounts);
      }
    });

    window.ethereum.on("chainChanged", (chainId) => {});
    web3 = new Web3(window.ethereum);
  } else {
    let providerList = [
      "https://bsc-dataseed2.defibit.io/",
      "https://bsc-dataseed3.defibit.io/",
      "https://bsc-dataseed4.defibit.io/",
      "https://bsc-dataseed2.ninicoin.io/",
      "https://bsc-dataseed3.ninicoin.io/",
      "https://bsc-dataseed4.ninicoin.io/",
      "https://bsc-dataseed1.binance.org/",
      "https://bsc-dataseed2.binance.org/",
      "https://bsc-dataseed3.binance.org/",
      "https://bsc-dataseed4.binance.org/",
    ];
    web3 = new Web3(
      providerList[Math.floor(Math.random() * providerList.length)]
    );
  }

  dprContract = new web3.eth.Contract(DPR.abi, DPR.address);
  dprTestContract = new web3.eth.Contract(DPR.abi, DPR.address_test);
  return false;
}

function numToBN(number, level = 18) {
  return new BN(number + "")
    .mul(new BN("10").pow(new BN(level + "")))
    .toString();
}

let txList = [];
if (window.localStorage.getItem("tx")) {
  txList = JSON.parse(window.localStorage.getItem("tx"));
}
function setTx(hash) {
  txList.push({ time: Date.now(), hash, status: "" });
  window.localStorage.setItem("tx", JSON.stringify(txList));
}

const ethService = {
  init,

  async metamask() {
    if (typeof window.ethereum === "undefined") {
      alert("MetaMask is not installed!");
      return;
    }
    ethereum.request({ method: "eth_requestAccounts" });
    web3.setProvider(Web3.givenProvider);
    return;
  },
  getAccount() {
    return web3.eth.getAccounts();
  },
  async getChainId() {
    let chainId = await web3.eth.getChainId();
    console.log(chainId);
    return chainId;
  },
  async getBalance(account) {
    let chainId = await web3.eth.getChainId();
    console.log(chainId);
    if (chainId / 1 == "56") {
      return dprContract.methods.balanceOf(account).call();
    }
  },
  getSearchWeb3() {
    let providerList = [
      "https://bsc-dataseed2.defibit.io/",
      "https://bsc-dataseed3.defibit.io/",
      "https://bsc-dataseed4.defibit.io/",
      "https://bsc-dataseed2.ninicoin.io/",
      "https://bsc-dataseed3.ninicoin.io/",
      "https://bsc-dataseed4.ninicoin.io/",
      "https://bsc-dataseed1.binance.org/",
      "https://bsc-dataseed2.binance.org/",
      "https://bsc-dataseed3.binance.org/",
      "https://bsc-dataseed4.binance.org/",
    ];
    return new Web3(
      providerList[Math.floor(Math.random() * providerList.length)]
    );
  },
  async getETHBalance(address) {
    return web3.eth.getBalance(address);
  },
  async approve(address, amount, from) {
    let chainId = await web3.eth.getChainId();
    if (chainId / 1 != "56") {
      return;
    }
    const dprC = dprContract;
    const allowance = await dprC.methods.allowance(from, address).call();
    console.log(allowance);
    if (new BN(amount).lte(new BN(allowance))) {
      return;
    }
    let alertMessage =
      window.navigator.language == "zh-CN"
        ? "您需要支付两次，第一次支付是授权，第二次是质押。"
        : "Your wallet needs to be authorized first.You need to pay twice, the first time is authorization, the second time is staking.";
    alert(alertMessage);
    let maxNum = Array.from({ length: 40 }).reduce((preVal, curVal) => {
      return preVal + "F";
    }, "0X");
    return dprC.methods.approve(address, maxNum).send({ from, gas: 2000000 });
  },
  tools: {
    numToBN,
  },
  deviceStake: {
    getStaking(account) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      return deviceStakeContract.methods.getStaking(account).call();
    },
    getAddressByDPRAddress(dprAddress) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      return deviceStakeContract.methods
        .getUserAddressByDPRAddress(dprAddress)
        .call();
    },
    getUserDPRAddress(account) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      return deviceStakeContract.methods.getUserDPRAddress(account).call();
    },
    getUserStakingTime(account) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      return deviceStakeContract.methods.getUserStakingTime(account).call();
    },
    async stake(account, dprAddress, _amount) {
      console.log("stake");
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      if (dprAddress.length < 35) {
        throw "wrong deeper chain";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      let amount = numToBN(_amount);
      const approve = await ethService.approve(
        DeviceStake[web3.currentProvider.chainId / 1].address,
        amount,
        account
      );
      const methodTx = deviceStakeContract.methods.stake(dprAddress, amount);
      const gas = await methodTx.estimateGas({ from: account });
      const gasPrice = await web3.eth.getGasPrice();
      return methodTx
        .send({
          from: account,
          gas: parseInt(gas * 2),
          gasPrice,
        })
        .on("transactionHash", (tx) => {
          setTx(tx);
          console.log(tx);
        });
    },
    async dealerStake(account, dprAddress, _amount) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      let amount = numToBN(_amount);
      const approve = await ethService.approve(
        DeviceStake[web3.currentProvider.chainId / 1].address,
        amount,
        account
      );

      const methodTx = deviceStakeContract.methods.stake(dprAddress, amount);
      const gas = await methodTx.estimateGas({ from: account });
      const gasPrice = await web3.eth.getGasPrice();
      return methodTx
        .send({
          from: account,
          gas: parseInt(gas * 2),
          gasPrice,
        })
        .on("transactionHash", (tx) => {
          setTx(tx);
          console.log(tx);
        });
    },
    async addStake(account, _amount) {
      if (!web3.currentProvider) {
        throw "Please connect your wallet.";
      }
      console.log("addStake");
      const deviceStakeContract = new web3.eth.Contract(
        DeviceStake.abi,
        DeviceStake[web3.currentProvider.chainId / 1].address
      );
      let amount = numToBN(_amount);
      const approve = await ethService.approve(
        DeviceStake[web3.currentProvider.chainId / 1].address,
        amount,
        account
      );
      const methodTx = deviceStakeContract.methods.addAndExtendStaking(amount);
      const gas = await methodTx.estimateGas({ from: account });
      const gasPrice = await web3.eth.getGasPrice();
      return methodTx
        .send({
          from: account,
          gas: parseInt(gas * 2),
          gasPrice,
        })
        .on("transactionHash", (tx) => {
          setTx(tx);
          console.log(tx);
        });
    },
    async getTransactionsStatus(tx) {
      return web3.eth.getTransactionReceipt(tx);
    },
    updateTxStatus(tx, status) {
      for (let i = 0; i < txList.length; i++) {
        if (txList[i].hash == tx) {
          txList[i].status = status;
          window.localStorage.setItem("tx", JSON.stringify(txList));
          return;
        }
      }
    },
  },
};

export default ethService;
